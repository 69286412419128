<template>
    <div class="activity_blacklist">
        <el-card>
            <nav class="out">
                <el-button type="primary" size="small" @click="onAdd">{{
                    '添加'
                }}</el-button>
            </nav>
        </el-card>
        <el-card style="margin-top: 10px">
            <el-table
                :data="list"
                style="width: 100%"
                border
                stripe
                :header-cell-style="rowClass"
            >
                <el-table-column label="userId" prop="userId"></el-table-column>
                <el-table-column label="头像" prop="avatar">
                    <template #default="{ row }">
                        <p-image :src="row.avatar"></p-image>
                    </template>
                </el-table-column>
                <el-table-column label="姓名" prop="name"></el-table-column>
                <el-table-column label="公司" prop="company"></el-table-column>

                <el-table-column label="操作">
                    <template #default="{ row }">
                        <el-button
                            type="text"
                            icon="el-icon-delete"
                            size="mini"
                            style="color: red"
                            @click="del(row)"
                            >删除</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <div-pagination
                :total="total"
                :search="search"
                @currentChange="currentChange"
            ></div-pagination>
        </el-card>
        <pop ref="pop" title="添加黑名单" @toAudit="addToUser">
            <!-- <el-input v-model="form.userId" placeholder="请输入用户ID"></el-input> -->
            <fuzzy-selection
                type="2"
                clearable
                :value.sync="form.userId"
                tipName="输入手机号，用户ID，姓名"
                all
                width="100%"
            ></fuzzy-selection>
        </pop>
    </div>
</template>

<script>
// import loadingButtonVue from './loading-button.vue'
import list from '/src/mixin/list.js'
import fuzzySelection from '@/components/fuzzySelection.vue'
export default {
    mixins: [list],

    data() {
        return {
            list: [],
            search: {
                page: 1,
                pageSize: 10,
            },
            total: 0,
            form: {
                userId: '',
            },
        }
    },
    created() {
        this.getList()
    },
    methods: {
        async getList() {
            let { data: res } = await this.$http.get(
                '/admin/Activity/getActivityBlacklist',
                { params: this.search }
            )
            this.list = res.data.list
            this.total = res.data.totalCount || 0
        },
        onAdd() {
            this.$refs.pop.show = true
            this.form = this.$options.data.call(this).form
        },
        async addToUser() {
            let { data: res } = await this.$http.post(
                '/admin/Activity/activityBlackListAdd',
                this.form
            )
            if (res.errorCode == 200) {
                this.$message.success(res.message)
                // this.search.page = 1
                this.getList()
                this.$refs.pop.show = false
            }
        },
        async del(row) {
            var id = row.id
            await this.$confirm('此操作将永久删除, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
            this.$http
                .post('/admin/Activity/activityBlacklistDel', {
                    id: id,
                })
                .then(({ data: res }) => {
                    if (res.errorCode == 200) {
                        this.$message.success(res.message)
                        if (this.total % 10 == 1) {
                            this.search.page--
                        }
                        this.getList()
                    }
                })
        },
        currentChange(e) {
            this.search.page = e
            this.getList()
        },
    },
    components: {
        fuzzySelection,
    },
}
</script>

<style lang="less" scoped>
</style>